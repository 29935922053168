import React, {useState} from "react"
import { graphql } from 'gatsby'
import Shell from '../components/global/shell'
import Container from '../components/global/container'
import {groupBy }from '../_utils/helperfunctions'
import SearchBox from '../components/scales/searchbox/searchbox'
import Helmet from "react-helmet"
import Beer from '../images/buymeabeer.png'
import {useCookies} from 'react-cookie'

const Scales = (props) => {

  // Get scale data
  const yaml = props.data.scales.nodes;
  var subscales = groupBy(yaml, 'scale');

  // Manage cookies
  const [cookies, setCookie, removeCookie] = useCookies(['variable', 'aesthetic']);
  
  console.log(cookies);
  // Initialize states
  const [variable, setVariable] = useState(cookies.variable == "null" ? null : cookies.variable);
  const [aesthetic, setAesthetic] = useState(cookies.aesthetic == "null" ? null : cookies.aesthetic);
  const [activeScales, setActiveScales] = useState(subscales);
  const [currentScale, setCurrentScale] = useState(null);

  // console.log(activeScales);

  return (
    <Shell>
      <Helmet>
        <title>The complete guide to scales</title>
      </Helmet>
      
      <div className='md:h-auto flex flex-rows flex-wrap md:flex-nowrap pb-20 min-h-screen'>
        <Container>
          <div className='mx-auto h-100 order-1 md:order-2 mb-20 md:mb-0 mt-10'>

            <div className='flex flex-col md:flex-row justify-between'>
              <div className='md:w-2/3' id='info'>
                <h1 className='text-xl md:text-3xl font-bold mb-5'>A complete guide to scales</h1>
                <p className='text-base md:text-lg text-gray-700 md:leading-relaxed'>There are numerous scales in ggplot2. Too many to memorize. This app makes it easy for you to find the right scales and arguments for your variable types and aesthetics. </p>
              </div>
              <div className='mt-5 md:mt-0 flex'>
                <a href="https://www.buymeacoffee.com/ggplot2tor" rel="noreferrer" target="_blank" className='w-32 md:w-40'><img className='rounded' src={Beer} /></a>
              </div>
            </div>


            <SearchBox activeScales={activeScales} 
                       setVariable={setVariable}
                       setAesthetic={setAesthetic} 
                       setCookie={setCookie}
                       setActiveScales={setActiveScales}
                       currentScale={currentScale}
                       setCurrentScale={setCurrentScale}
                       data={yaml}
                       variable={variable} 
                       aesthetic={aesthetic} />

          </div>
        </Container>
      </div>

    </Shell>
  )
}

export default Scales



export const query = graphql`
  query {
    scales: allYaml(filter: {scale: {regex: "/scale/"}}) {
      nodes {
        id
        scale
        aesthetic
        variable_type
        attribute
        information
      }
    }
  }
`