function groupBy(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};


/*
 * Filter the scales for current aesthetic and variable
 */
function getScales(data, variable, aesthetic) {
  const variableNames = ['discrete', 'continuous', 'ordered', 'date', 'datetime', 'time'];
  const aestheticNames = ['x', 'y', 'fill', 'color', 'linetype', 'size', 'alpha', 'shape'];

  if (variable || aesthetic) {

    if (variableNames.includes(variable) || aestheticNames.includes(aesthetic)) {

      var newData = null;

      if (variable && aesthetic) {
        newData = data.filter(e => {
          return(e.aesthetic === aesthetic && e.variable_type === variable);
        });
      } else if (variable && !aesthetic) {
         newData = data.filter(e => {
          return(e.variable_type === variable);
        });
      } else {
        newData = data.filter(e => {
          return(e.aesthetic === aesthetic);
        });
      }

      const activeScales = groupBy(newData, 'scale');

      return(activeScales);
    }
  }

  return(groupBy(data, 'scale'))
}


export { groupBy, getScales }