import React, {useEffect} from 'react';
import {getScales} from '../../../_utils/helperfunctions'
import {MdClear} from 'react-icons/md'
import {FaRegSadCry} from 'react-icons/fa'
import {Link} from 'gatsby'


export default function SearchBox(props) {

  const variableClicked = (e) => {
    var variable = null;

    if (e) {
      variable = e.currentTarget.innerHTML;
    }

    props.setVariable(variable);
    const scales = getScales(props.data, variable, props.aesthetic);
    props.setCookie('variable', variable);
    props.setActiveScales(scales);
  }

  const aestheticClicked = (e) => {
    var aesthetic = null;

    if (e) {
      aesthetic = e.currentTarget.innerHTML;
    }

    // console.log(aesthetic);

    props.setAesthetic(aesthetic);
    const scales = getScales(props.data, props.variable, aesthetic);

    console.log(scales);

    props.setCookie('aesthetic', aesthetic);
    props.setActiveScales(scales);
  }
  
  // Make sure that the right variables and
  // aesthetics are visible on page load (filter)
  useEffect(() => {
    const scales = getScales(props.data, props.variable, props.aesthetic);
    props.setActiveScales(scales);
    console.log('initial');
  }, []);

  console.log(props.activeScales);

  const variables = ['discrete', 'continuous', 'time', 'date', 'datetime'];
  const aesthetics = ['x', 'y', 'fill', 'color', 'linetype', 'size', 'alpha', 'shape'];

  return (
    <div className='mb-5 py-5 flex flex-col'>
      
      <div className='w-100 justify-between my-5 '>
        <p className='text-base font-bold mb-2'>Choose the variable and aesthetic for which you need to find a scale:</p>

        <div className="flex flex-col md:flex-row">

          <div className='bg-gray-200 p-4 rounded md:mr-5'>
            <span className='text-xs text-gray-500 font-bold uppercase block mb-5 flex justify-between align-baseline'><span>Variable type </span>{props.variable ? <MdClear className='cursor-pointer text-sm hover:text-gray-900' onClick={() => variableClicked(null)} /> : ''}</span>

            <div className='flex flex-wrap'>
              {variables.map((v, i) => 
              <span data-index={i} className={`text-gray-700 hover:bg-yellow-50 rounded mb-3 bg-white text-base md:text-sm py-1 px-1 cursor-pointer mr-3 ${v === props.variable ? 'active bg-yellow-100' : ''}`} key={i} onClick={(e) => variableClicked(e)}>{v}
              </span>)}
            </div>

          </div>

          <div className='bg-gray-200 p-4 rounded mt-5 md:mt-0'>
            <span className='text-xs text-gray-500 font-bold uppercase block mb-5 flex justify-between align-baseline'><span>Aesthetic</span> {props.aesthetic ? <MdClear className='cursor-pointer text-sm hover:text-gray-900 ' onClick={() => aestheticClicked(null)} /> : ''}</span>

            <div className='flex flex-wrap'>
              {aesthetics.map((v, i) => 
              <span data-index={i} className={`text-gray-700 hover:bg-yellow-50 rounded mb-3 bg-white text-base md:text-sm py-1 px-1 cursor-pointer mr-3 ${v === props.aesthetic ? 'active bg-yellow-100' : ''}`} key={i} onClick={(e) => aestheticClicked(e)}>{v}
              </span>)}
            </div>

          </div>
        </div>

      </div>

      <div className='w-100 my-5'>
        <p className='text-lg mb-3'>All available scales for your aesthetics and variables:</p>

        <div className='flex flex-row flex-wrap'>
          {Object.keys(props.activeScales).sort().map((e, i) => {

            // const active = e === (props.currentScale ? props.currentScale.name : null) ? 'font-bold' : ''

            const link = `/scales/${e}`

            return(<Link to={link} key={i} data-scale={e} className={`text-xs md:text-sm py-1 pr-3 text-cblue-six hover:underline cursor-pointer w-1/2 md:w-1/3 lg:w-2/12 `}>{e}</Link>)
          })}

          {Object.keys(props.activeScales).length === 0 ? <p className='flex text-lg p-4 bg-red-50 rounded md:w-2/3'><FaRegSadCry className='text-5xl text-red-400 mr-3' /> I am sorry! This variable-aesthetic combination either does not exist or is not recommended. Choose a different combination on the left panel.</p> : ''}
        </div>

      </div>
      
    </div>
  )
}